import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './whyWorth.module.scss';
import { innerHTMLTranslation } from 'src/utils/utils';
import JarImg from '../../images/growth.svg';

const WhyWorth = () => {
  const { t } = useTranslation();

  return (
    <div
      id="WhyWorth"
      className={styles.whyWorth}
    >
      <div className={styles.whyWorth_wrapper}>
        <h2 className={`black-header ${styles.whyWorth_header}`}>{t('why_worth_question')}</h2>
        <div
          className={styles.whyWorth_explain}
          dangerouslySetInnerHTML={innerHTMLTranslation(t('wy_worth_explain'))}
        />
        <div className={styles.whyWorth_listWrapper}>
          <ul className={styles.whyWorth_list}>
            <li className={styles.whyWorth_list__item}>{t('easy_in_use')}</li>
            <li className={styles.whyWorth_list__item}>{t('quick_transactions')}</li>
            <li className={styles.whyWorth_list__item}>{t('free_access')}</li>
            <li className={styles.whyWorth_list__item}>{t('full_control')}</li>
            <li className={styles.whyWorth_list__item}>{t('safe_transactions')}</li>
          </ul>
          <img
            className={styles.whyWorth_jarImage}
            src={JarImg}
            alt="PayTip Jar"
          />
        </div>
      </div>
    </div>
  );
};

export default WhyWorth;
