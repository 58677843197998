import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './cookiesInfo.module.scss';

const CookiesInfo = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  const handleCookies = () => {
    if (window !== 'undefined') {
      window.localStorage.setItem('cookiesInfo', 'true');
    }
    setOpen(false);
  };

  return (
    <>
      { open && typeof window !== 'undefined' && !window.localStorage.getItem('cookiesInfo') && (
        <div className={styles.cookiesInfo}>
          <div className={styles.cookiesInfo_wrapper}>
            <div className={styles.cookiesInfo_text}>
              {t('cookie_info')}
            </div>
            <button
              className={styles.cookiesInfo_button}
              onClick={handleCookies}
              type="button"
            >
              {t('got_it')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesInfo;
