import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { animateScroll } from 'react-scroll';
import ToTopImg from '../images/toTop.svg';

import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';
import Top from '../components/top/top';
import ForWho from '../components/forWho/forWho';
import WhyWorth from '../components/whyWorth/whyWorth';
import HowWorks from '../components/howWorks/howWorks';
import Safety from '../components/safety/safety';
import Opinions from '../components/opinions/opinions';
import Contact from '../components/contact/contact';
import CookiesInfo from '../components/cookiesInfo/cookiesInfo';

const IndexPage = () => {
  const { t } = useTranslation();
  const [ showToTop, setShowToTop ] = useState(false);

  const handleScrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 500,
      smooth: true,
    });
  };

  const myScrollFunc = () => {
    const y = window.scrollY;
    if (y >= 80) {
      setShowToTop(true);
    } else {
      setShowToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', myScrollFunc);
  }, []);

  return (
    <Layout>
      <SEO title="Home" />
      <Top />
      <ForWho />
      <WhyWorth />
      <HowWorks />
      <Safety />
      <Opinions />
      <Contact />
      <CookiesInfo />
      <button
        onClick={handleScrollToTop}
        className={`${styles.index_toTop} ${showToTop ? 'fade-in' : 'fade-out'}`}
        type="button"
      >
        <img
          src={ToTopImg}
          alt="Back to top"
        />
        <div>
          {t('to_top')}
        </div>
      </button>
    </Layout>
  );
};

export default IndexPage;
