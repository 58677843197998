import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import styles from './opinions.module.scss';
import { innerHTMLTranslation } from 'src/utils/utils';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/style/react-slick-override.scss';

const Opinions = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      opinion1: file(relativePath: { eq: "men-s-white-dress-shirt.png" }) {
        childImageSharp {
          fluid (maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      opinion2: file(relativePath: { eq: "person-in-teal-ice-cream-print-tank-top-holds-ice-cream.png" }) {
        childImageSharp {
          fluid (maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      opinion3: file(relativePath: { eq: "portrait-of-young-woman-using-mobile-phone-in-cafe.png" }) {
        childImageSharp {
          fluid (maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const settings = {
    dots: true,
    arrows: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div
      id="Opinions"
      className={styles.opinions}
    >
      <div className={styles.opinions_wrapper}>
        <h2 className={`black-header ${styles.opinions_header}`}>{t('opinions')}</h2>
      </div>
      <div>
        <Slider
          className={`${styles.opinions_slider} opinions_arrows`}
          {...settings}
        >
          <div>
            <Img
              className={styles.opinions_slider__image}
              fluid={data.opinion1.childImageSharp.fluid}
              alt="Men in white dress shirt"
            />
            <div
              className={styles.opinions_slider__header}
            >
              "{t('application_is_marvelous')}"
            </div>
            <div className={styles.opinions_slider__text}>
              {t('problem_with_cash')}
            </div>
            <div className={styles.opinions_slider__who}>
              {t('piotr_waiter')}
            </div>
          </div>
          <div>
            <Img
              className={styles.opinions_slider__image}
              fluid={data.opinion2.childImageSharp.fluid}
              alt="Person in teal ice cream print tank top holds ice cream"
            />
            <div
              className={styles.opinions_slider__header}
            >
              "{t('i_looking_this')}"
            </div>
            <div className={styles.opinions_slider__text}>
              {t('i_dont_know_qr')}
            </div>
            <div className={styles.opinions_slider__who}>
              {t('jan_food_delivery')}
            </div>
          </div>
          <div>
            <Img
              className={styles.opinions_slider__image}
              fluid={data.opinion3.childImageSharp.fluid}
              alt="Portrait of young woman using mobile phone in cafe"
            />
            <div
              className={styles.opinions_slider__header}
            >
              "{t('my_clients_and_i')}"
            </div>
            <div className={styles.opinions_slider__text}>
              {t('clients_leave_tips')}
            </div>
            <div className={styles.opinions_slider__who}>
              {t('anna_nails_stylist')}
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Opinions;
