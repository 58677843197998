import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import { innerHTMLTranslation } from 'src/utils/utils';
import styles from './contact.module.scss';

const Contact = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      payTipFb: file(relativePath: { eq: "paytip_fb.png" }) {
        childImageSharp {
          fluid (maxWidth: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      payTipYt: file(relativePath: { eq: "paytip_yt.png" }) {
        childImageSharp {
          fluid (maxWidth: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      payTipIn: file(relativePath: { eq: "paytip_in.png" }) {
        childImageSharp {
          fluid (maxWidth: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      payTipInsta: file(relativePath: { eq: "paytip_insta.png" }) {
        childImageSharp {
          fluid (maxWidth: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      payTipMail: file(relativePath: { eq: "paytip_mail.png" }) {
        childImageSharp {
          fluid (maxWidth: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={styles.contact}>
      <div className={styles.contact_wrapper}>
        <div className={styles.contact_header}>
          Poznaj nas bliżej:
        </div>
        <div className={styles.contact_social}>
          <a href="https://bit.ly/3hLCwf1">
            <Img
              className={styles.contact_social__image}
              fluid={data.payTipFb.childImageSharp.fluid}
              alt="PayTip Facebook logo"
            />
          </a>
          <a href="https://bit.ly/3ePuTlS">
            <Img
              className={styles.contact_social__image}
              fluid={data.payTipYt.childImageSharp.fluid}
              alt="PayTip YouTube logo"
            />
          </a>
          <a href="https://bit.ly/2Ceyxaz">
            <Img
              className={styles.contact_social__image}
              fluid={data.payTipIn.childImageSharp.fluid}
              alt="PayTip LinkedIn logo"
            />
          </a>
          <a href="https://bit.ly/37KgC7t">
            <Img
              className={styles.contact_social__image}
              fluid={data.payTipInsta.childImageSharp.fluid}
              alt="PayTip Instagram logo"
            />
          </a>
          <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#102;&#111;&#64;&#112;&#97;&#121;&#116;&#105;&#112;&#46;&#105;&#111;">
            <Img
              className={styles.contact_social__image}
              fluid={data.payTipMail.childImageSharp.fluid}
              alt="PayTip Email logo"
            />
          </a>
        </div>
        <div
          className={styles.contact_info}
          dangerouslySetInnerHTML={innerHTMLTranslation(t('contact_info'))}
        />
      </div>
    </div>
  );
};

export default Contact;
