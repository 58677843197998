import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import styles from './safety.module.scss';
import { innerHTMLTranslation } from 'src/utils/utils';

const Safety = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
  query {
    knfLogo: file(relativePath: { eq: "knf_logo.png" }) {
      childImageSharp {
        fixed (width: 153) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    paymentMethods: file(relativePath: { eq: "payment_methods.png" }) {
      childImageSharp {
        fluid (maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`);

  return (
    <div
      id="Safety"
      className={styles.safety}
    >
      <div className={styles.safety_wrapper}>
        <h2 className={`black-header ${styles.safety_header}`}>{t('safety')}</h2>
        <div className={styles.safety_supervision}>
          {t('knf_supervision')}
        </div>
        <Img
          className={styles.safety_knfLogo}
          fixed={data.knfLogo.childImageSharp.fixed}
          alt="KNF Logo"
        />
        <div
          className={styles.safety_explain}
          dangerouslySetInnerHTML={innerHTMLTranslation(t('safety_explain'))}
        />
        <Img
          className={styles.safety_paymentMethods}
          fluid={data.paymentMethods.childImageSharp.fluid}
          alt="Payment methods"
        />
      </div>
    </div>
  );
};

export default Safety;
