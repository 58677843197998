import React, { useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { innerHTMLTranslation } from 'src/utils/utils';
import { useTranslation } from 'react-i18next';
import GiverQrCode from '../../images/giver_qrcode.svg';
import PayTipSignUpQrCode from '../../images/signup_qrcode.svg';
import styles from './forWho.module.scss';

const ForWho = () => {
  const { t } = useTranslation();
  const waiterMoreRef = useRef();
  const clientMoreRef = useRef();
  const waiterMoreDesktopRef = useRef();
  const waiterMoreDesktopButtonRef = useRef();
  const clientMoreDesktopRef = useRef();
  const clientMoreDesktopButtonRef = useRef();
  const verticalLineRef = useRef();
  const rightSideRef = useRef();
  const leftSideRef = useRef();

  const [isWaiterMoreOn, setIsWaiterMoreOn] = useState(false);
  const [isClientMoreOn, setIsClientMoreOn] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      waiter: file(relativePath: { eq: "waiter.png" }) {
        childImageSharp {
          fixed (width: 230, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      women: file(relativePath: { eq: "woman_customer.png" }) {
        childImageSharp {
          fixed (width: 230, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clientsBackgroundDesktop: file(relativePath: { eq: "clients_bg_desktop.png" }) {
        childImageSharp {
          fluid (quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      clientsBackgroundMobile: file(relativePath: { eq: "clients_bg_mobile.png" }) {
        childImageSharp {
          fluid (quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const clients = [
    'Kelner',
    'Fryzjer',
    'Salon urody',
    'Kurier',
    'Kawiarnia',
    'Dostawca jedzenia',
    'Kosmetyczka',
    'Artysta uliczny',
    'Kasyno',
    'Szatnia',
    'Recepcja',
    'Obsługa hotelowa',
    'Kierowca Taxi',
    'Autodetaling',
    'Kierowca autobusu',
    'Myjnia samochodowa'
  ];

  const handleWaiterMobileClick = (event) => {
    setIsWaiterMoreOn(!isWaiterMoreOn);
    waiterMoreRef.current.classList.toggle(styles.forWho_more__show);
    event.target.classList.toggle(styles.forWho_more__buttonChange);
  };

  const handleClientMobileClick = (event) => {
    setIsClientMoreOn(!isClientMoreOn);
    clientMoreRef.current.classList.toggle(styles.forWho_more__show);
    event.target.classList.toggle(styles.forWho_more__buttonChange);
  };

  const handleWaiterDesktopClick = () => {
    setIsWaiterMoreOn(!isWaiterMoreOn);
    waiterMoreDesktopRef.current.classList.toggle(styles.forWho_moreDesktop__show);
    verticalLineRef.current.classList.toggle(styles.forWho_moreDesktop__hide);
    rightSideRef.current.classList.toggle(styles.forWho_moreDesktop__hide);
    waiterMoreDesktopButtonRef.current.classList.toggle(styles.forWho_more__buttonOpacity);
  };

  const handleClientDesktopClick = () => {
    setIsClientMoreOn(!isClientMoreOn);
    clientMoreDesktopRef.current.classList.toggle(styles.forWho_moreDesktop__show);
    verticalLineRef.current.classList.toggle(styles.forWho_moreDesktop__hide);
    leftSideRef.current.classList.toggle(styles.forWho_moreDesktop__hide);
    clientMoreDesktopButtonRef.current.classList.toggle(styles.forWho_more__buttonOpacity);
  };

  return (
    <div
      id="ForWho"
      className={styles.forWho}
    >
      <div className={styles.forWho_wrapper}>
        <h2 className="black-header">{t('for_who_paytip_is')}</h2>
        <div className={styles.forWho_usersWrapper}>
          <div
            className={styles.forWho_userLayout}
            ref={leftSideRef}
          >
            <div className={styles.forWho_info}>
              <Img
                fixed={data.waiter.childImageSharp.fixed}
                alt="A Waiter smiling happily"
              />
              <h3 className={styles.forWho_info__header}>{t('waiters')}</h3>
              <div className={styles.forWho_info__text}>{t('and_other_services')}</div>
              <div
                className={styles.forWho_more}
                ref={waiterMoreRef}
              >
                <div className={styles.forWho_more__header}>
                  "{t('thanks_for_paytip')}"
                </div>
                <div
                  dangerouslySetInnerHTML={innerHTMLTranslation(t('waiter_more'))}
                />
                <a
                  className="general-button"
                  href="https://app.paytip.pl/sign-up"
                >
                  {t('make_account')}
                </a>
              </div>
              <button
                className={`general-button ${styles.forWho_showMobile}`}
                onClick={handleWaiterMobileClick}
                type="button"
              >
                {isWaiterMoreOn ? t('back') : t('more')}
              </button>
              <button
                className={`general-button ${styles.forWho_showDesktop} ${styles.forWho_more__buttonMargin}`}
                onClick={handleWaiterDesktopClick}
                type="button"
                ref={waiterMoreDesktopButtonRef}
              >
                {t('more')}
              </button>
            </div>

            <div
              className={styles.forWho_moreDesktop}
              ref={waiterMoreDesktopRef}
            >
              <div className={styles.forWho_more__header}>
                "{t('thanks_for_paytip')}"
              </div>
              <div
                dangerouslySetInnerHTML={innerHTMLTranslation(t('waiter_more'))}
              />
              <img
                className={styles.forWho_moreDesktop__image}
                src={PayTipSignUpQrCode}
                alt="PayTip Sign Up QR Code"
              />
              <a
                className={`general-button ${styles.forWho_moreDesktop__margins} ${styles.forWho_moreDesktop__button}`}
                href="https://app.paytip.pl/sign-up"
              >
                {t('make_account')}
              </a>
              <button
                className={`general-button ${styles.forWho_more__buttonChange}`}
                onClick={handleWaiterDesktopClick}
                type="button"
              >
                {t('back')}
              </button>
            </div>
          </div>
          <div className={styles.forWho_horizontalLine} />
          <div
            className={styles.forWho_verticalLine}
            ref={verticalLineRef}
          />
          <div
            className={styles.forWho_userLayout}
            ref={rightSideRef}
          >

            <div
              className={`${styles.forWho_moreDesktop} ${styles.forWho_moreDesktop__right}`}
              ref={clientMoreDesktopRef}
            >
              <div className={styles.forWho_more__header}>
                "{t('client_more_header')}"
              </div>
              <div
                dangerouslySetInnerHTML={innerHTMLTranslation(t('client_more'))}
              />
              <a
                className={`general-button ${styles.forWho_moreDesktop__margins} ${styles.forWho_moreDesktop__button}`}
                href="https://giver.paytip.pl"
              >
                {t('try_it')}
              </a>
              <img
                className={styles.forWho_moreDesktop__image}
                src={GiverQrCode}
                alt="Giver QR Code"
              />
              <button
                className={`general-button ${styles.forWho_more__buttonChange}`}
                onClick={handleClientDesktopClick}
                type="button"
              >
                {t('back')}
              </button>
            </div>

            <div className={styles.forWho_info}>
              <Img
                fixed={data.women.childImageSharp.fixed}
                alt="A Client"
              />
              <h3 className={styles.forWho_info__header}>{t('clients')}</h3>
              <div className={styles.forWho_info__text}>{t('wants_to_paytip')}</div>
              <div
                className={styles.forWho_more}
                ref={clientMoreRef}
              >
                <div className={styles.forWho_more__header}>
                  "{t('client_more_header')}"
                </div>
                <div
                  dangerouslySetInnerHTML={innerHTMLTranslation(t('client_more'))}
                />
                <a
                  className="general-button"
                  href="https://giver.paytip.pl/"
                >
                  {t('try_it')}
                </a>
              </div>
              <button
                className={`general-button ${styles.forWho_showMobile}`}
                onClick={handleClientMobileClick}
                type="button"
              >
                {isClientMoreOn ? t('back') : t('more')}
              </button>
              <button
                className={`general-button ${styles.forWho_showDesktop} ${styles.forWho_more__buttonMargin}`}
                onClick={handleClientDesktopClick}
                type="button"
                ref={clientMoreDesktopButtonRef}
              >
                {t('more')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <BackgroundImage
        Tag="div"
        className={`${styles.forWho_clients} ${styles.forWho_showDesktop}`}
        fluid={data.clientsBackgroundDesktop.childImageSharp.fluid}
      >
        {
          clients.map((client) => (
            <span
              key={client}
              className={styles.forWho_clients__item}
            >
              {client}
            </span>)
          )
        }
      </BackgroundImage>
      <BackgroundImage
        Tag="div"
        className={`${styles.forWho_clients} ${styles.forWho_showMobile}`}
        fluid={data.clientsBackgroundMobile.childImageSharp.fluid}
      >
        {
          clients.map((client) => (
            <span
              key={client}
              className={styles.forWho_clients__item}
            >
              {client}
            </span>)
          )
        }
      </BackgroundImage>
    </div>
  );
};

export default ForWho;
