import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Img from 'gatsby-image';
import styles from './top.module.scss';
import PayTipQrCode from '../../images/paytip_qrcode.svg';
import Slider from 'react-slick';

const Top = () => {
  const { t } = useTranslation();

  // TODO: Waiting for gatsby update - passing variables to query
  // TODO: Helping functions below

  // const brakePointGenerator = (min, max, step) => {
  //   const brakePoints = [];
  //   for (let i = min; i < max; i+=step) {
  //     brakePoints.push(i);
  //   }
  //   return brakePoints;
  // };

  // const brakePoints = brakePointGenerator(150, 650, 5);

  // const brakePointsArr = [
  //   92,
  //   111,
  //   128,
  //   144,
  //   157,
  //   170,
  //   182,
  //   192,
  //   203,
  //   204,
  //   280,
  //   281,
  //   288,
  //   295,
  //   302,
  //   309,
  //   317,
  //   322,
  //   329,
  //   335,
  //   342,
  //   348,
  //   354,
  //   361,
  //   367,
  //   372,
  //   378,
  //   384,
  //   389,
  //   394,
  //   400,
  //   405,
  //   413,
  //   420
  // ];

  // const calculateSizes = (brakePoints, min, max) => {
  //   let step = (max - min) / brakePoints.length;
  //   step = Math.round(step);
  //   let startingValue = min;
  //   let returnString = '';
  //   for (let index = 0; index < brakePoints.length; index++) {
  //     const returnValue = startingValue + step;

  //     returnString = `${returnString} (max-width: ${startingValue}px) ${brakePoints[index]}px,`;

  //     startingValue = returnValue;
  //   }
  //   console.log(returnString);
  // };

  // calculateSizes(brakePointsArr, 768, 2615);

  const data = useStaticQuery(graphql`
    query {
      hand: file(relativePath: { eq: "hand.png" }) {
        childImageSharp {
          fluid (
            srcSetBreakpoints: [
              175,
              228,
              272,
              311,
              349,
              382,
              413,
              439,
              495,
              519,
              541,
              564,
              588,
              610,
              630,
              654,
              652,
              656
            ],
            sizes: "(max-width: 768px) 175px, (max-width: 865px) 228px, (max-width: 962px) 272px, (max-width: 1059px) 311px, (max-width: 1156px) 349px, (max-width: 1253px) 382px, (max-width: 1350px) 413px, (max-width: 1447px) 439px, (max-width: 1641px) 495px, (max-width: 1738px) 519px, (max-width: 1835px) 541px, (max-width: 1932px) 564px, (max-width: 2029px) 588px, (max-width: 2126px) 610px, (max-width: 2223px) 630px, (max-width: 2320px) 654px, (max-width: 2417px) 652px, (max-width: 2514px) 656px, 656px"
          )
          {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carousel1: file(relativePath: { eq: "hand_carousel1.png" }) {
        childImageSharp {
          fluid (
            maxWidth: 420,
            srcSetBreakpoints: [
              92,
              111,
              128,
              144,
              157,
              170,
              182,
              192,
              203,
              204,
              288,
              295,
              302,
              309,
              317,
              322,
              329,
              335,
              342,
              348,
              354,
              361,
              367,
              372,
              378,
              384,
              389,
              394,
              400,
              405,
              413
            ],
            sizes: "(max-width: 768px) 92px, (max-width: 822px) 111px, (max-width: 876px) 128px, (max-width: 930px) 144px, (max-width: 984px) 157px, (max-width: 1038px) 170px, (max-width: 1092px) 182px, (max-width: 1146px) 192px, (max-width: 1200px) 203px, (max-width: 1254px) 204px, (max-width: 1416px) 288px, (max-width: 1470px) 295px, (max-width: 1524px) 302px, (max-width: 1578px) 309px, (max-width: 1632px) 317px, (max-width: 1686px) 322px, (max-width: 1740px) 329px, (max-width: 1794px) 335px, (max-width: 1848px) 342px, (max-width: 1902px) 348px, (max-width: 1956px) 354px, (max-width: 2010px) 361px, (max-width: 2064px) 367px, (max-width: 2118px) 372px, (max-width: 2172px) 378px, (max-width: 2226px) 384px, (max-width: 2280px) 389px, (max-width: 2334px) 394px, (max-width: 2388px) 400px, (max-width: 2442px) 405px, (max-width: 2496px) 413px, (max-width: 2550px) 420px, 420px"
            ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carousel2: file(relativePath: { eq: "hand_carousel2.png" }) {
        childImageSharp {
          fluid (
            maxWidth: 420,
            srcSetBreakpoints: [
              92,
              111,
              128,
              144,
              157,
              170,
              182,
              192,
              203,
              204,
              281,
              288,
              295,
              302,
              309,
              317,
              322,
              329,
              335,
              342,
              348,
              354,
              361,
              367,
              372,
              378,
              384,
              389,
              394,
              400,
              405,
              413
            ],
            sizes: "(max-width: 768px) 92px, (max-width: 822px) 111px, (max-width: 876px) 128px, (max-width: 930px) 144px, (max-width: 984px) 157px, (max-width: 1038px) 170px, (max-width: 1092px) 182px, (max-width: 1146px) 192px, (max-width: 1200px) 203px, (max-width: 1254px) 204px, (max-width: 1416px) 288px, (max-width: 1470px) 295px, (max-width: 1524px) 302px, (max-width: 1578px) 309px, (max-width: 1632px) 317px, (max-width: 1686px) 322px, (max-width: 1740px) 329px, (max-width: 1794px) 335px, (max-width: 1848px) 342px, (max-width: 1902px) 348px, (max-width: 1956px) 354px, (max-width: 2010px) 361px, (max-width: 2064px) 367px, (max-width: 2118px) 372px, (max-width: 2172px) 378px, (max-width: 2226px) 384px, (max-width: 2280px) 389px, (max-width: 2334px) 394px, (max-width: 2388px) 400px, (max-width: 2442px) 405px, (max-width: 2496px) 413px, (max-width: 2550px) 420px, 420px"
            ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carousel3: file(relativePath: { eq: "hand_carousel3.png" }) {
        childImageSharp {
          fluid (
            maxWidth: 420,
            srcSetBreakpoints: [
              92,
              111,
              128,
              144,
              157,
              170,
              182,
              192,
              203,
              204,
              281,
              288,
              295,
              302,
              309,
              317,
              322,
              329,
              335,
              342,
              348,
              354,
              361,
              367,
              372,
              378,
              384,
              389,
              394,
              400,
              405,
              413
            ],
            sizes: "(max-width: 768px) 92px, (max-width: 822px) 111px, (max-width: 876px) 128px, (max-width: 930px) 144px, (max-width: 984px) 157px, (max-width: 1038px) 170px, (max-width: 1092px) 182px, (max-width: 1146px) 192px, (max-width: 1200px) 203px, (max-width: 1254px) 204px, (max-width: 1416px) 288px, (max-width: 1470px) 295px, (max-width: 1524px) 302px, (max-width: 1578px) 309px, (max-width: 1632px) 317px, (max-width: 1686px) 322px, (max-width: 1740px) 329px, (max-width: 1794px) 335px, (max-width: 1848px) 342px, (max-width: 1902px) 348px, (max-width: 1956px) 354px, (max-width: 2010px) 361px, (max-width: 2064px) 367px, (max-width: 2118px) 372px, (max-width: 2172px) 378px, (max-width: 2226px) 384px, (max-width: 2280px) 389px, (max-width: 2334px) 394px, (max-width: 2388px) 400px, (max-width: 2442px) 405px, (max-width: 2496px) 413px, (max-width: 2550px) 420px, 420px"
            ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear'
  };

  return (
    <div
      id="Top"
      className={styles.top}
    >
      <div className={styles.top_wrapper}>
        <h2 className={styles.top_header}>
          {t('revolution_in_word')}
          <div className={styles.top_header__fancy}>{t('tip_header')}</div>
        </h2>
        <ul className={styles.top_intro}>
          <li className={styles.top_intro__item}>{t('dont_waste_your_tips')}</li>
          <li className={styles.top_intro__item}>{t('safe_transactions')}</li>
          <li className={styles.top_intro__item}>{t('instant_money')}</li>
        </ul>
        <div className={`${styles.top_qrcode} ${styles.top_qrcode__hideMobile}`}>
          <div className={styles.top_qrcode__text}>
            <div className={`${styles.top_qrcode__item} ${styles.top_qrcode__firstItem}`}>{t('test_paytip')}</div>
            <div className={styles.top_qrcode__item}>{t('follow_the_instructions_desktop')}</div>
          </div>
          <img
            className={styles.top_qrcode__image}
            src={PayTipQrCode}
            alt="PayTip QR Code"
          />
        </div>
        <div className={`${styles.top_qrcode} ${styles.top_qrcode__hideDesktop}`}>
          <div className={styles.top_qrcode__text}>
            <div className={`${styles.top_qrcode__item} ${styles.top_qrcode__firstItem}`}>{t('start_using_paytip')}</div>
            <div className={`${styles.top_qrcode__item} ${styles.top_qrcode__itemMobile}`}>{t('follow_the_instructions_mobile')}</div>
          </div>
          <div className={styles.top_qrcode__mobileButtons}>
            <a
              className={`${styles.top_qrcode__mobileButton} ${styles.top_qrcode__mobileButtonLeft}`}
              href="https://giver.paytip.pl/"
            >
              {t('give')}
            </a>
            <a
              className={`${styles.top_qrcode__mobileButton} ${styles.top_qrcode__mobileButtonRight}`}
              href="https://app.paytip.pl/"
            >
              {t('receive')}
            </a>
          </div>
        </div>
        <div className={styles.top_handImageWrapper}>
          <Slider
            className={`${styles.top_slider} howWorks_arrows`}
            {...settings}
          >
            <div>
              <Img
                className={styles.top_slider__image}
                fluid={data.carousel1.childImageSharp.fluid}
                alt="Cellphone with PayTip"
              />
            </div>
            <div>
              <Img
                className={styles.top_slider__image}
                fluid={data.carousel2.childImageSharp.fluid}
                alt="Cellphone with PayTip"
              />
            </div>
            <div>
              <Img
                className={styles.top_slider__image}
                fluid={data.carousel3.childImageSharp.fluid}
                alt="Cellphone with PayTip"
              />
            </div>
          </Slider>
          <Img
            className={styles.top_handImage}
            fluid={data.hand.childImageSharp.fluid}
            alt="A hand with cellphone"
          />
        </div>
      </div>
    </div>
  );
};

export default Top;
