import React, { useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import styles from './howWorks.module.scss';
import { innerHTMLTranslation } from 'src/utils/utils';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PayTipGradientQrCodeImg from '../../images/paytip_qrcode_gradient.svg';
import Fold from '../../images/fold.svg';
import 'react-tabs/style/react-tabs.scss';
import 'src/style/react-tabs-override.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/style/react-slick-override.scss';

const HowWorks = () => {
  const { t } = useTranslation();
  const giveMoreRef = useRef();
  const toggleButtonRef = useRef();

  const [isGiveMoreOn, setIsGiveMoreOn] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      giveTipCellphone1: file(relativePath: { eq: "give_tip_cellphone1.png" }) {
        childImageSharp {
          fluid (maxWidth: 245) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      giveTipCellphone2: file(relativePath: { eq: "give_tip_cellphone2.png" }) {
        childImageSharp {
          fluid (maxWidth: 245) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      giveTipCellphone3: file(relativePath: { eq: "give_tip_cellphone3.png" }) {
        childImageSharp {
          fluid (maxWidth: 245) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      receiveTipCellphone1: file(relativePath: { eq: "receive_tip_cellphone1.png" }) {
        childImageSharp {
          fluid (maxWidth: 245) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      receiveTipCellphone2: file(relativePath: { eq: "receive_tip_cellphone2.png" }) {
        childImageSharp {
          fluid (maxWidth: 245) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      receiveTipCellphone3: file(relativePath: { eq: "receive_tip_cellphone3.png" }) {
        childImageSharp {
          fluid (maxWidth: 245) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const settings = {
    dots: true,
    arrows: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleWhyQRClick = () => {
    setIsGiveMoreOn(!isGiveMoreOn);
    giveMoreRef.current.classList.toggle(styles.howWorks_more__show);
    toggleButtonRef.current.classList.toggle(styles.howWorks_more__buttonChange);
  };

  return (
    <div
      id="HowWorks"
      className={styles.howWorks}
    >
      <div className={styles.howWorks_wrapper}>
        <h2 className={`black-header ${styles.howWorks_header}`}>{t('how_works_question')}</h2>
        <div
          className={styles.howWorks_explain}
          dangerouslySetInnerHTML={innerHTMLTranslation(t('how_works_explain'))}
        />
      </div>
      <Tabs className={styles.howWorks_tabs}>
        <TabList>
          <Tab>{t('giving_tip')}</Tab>
          <Tab>{t('receive_tip')}</Tab>
        </TabList>

        <TabPanel>
          <Slider
            className={`${styles.howWorks_slider} howWorks_arrows`}
            {...settings}
          >
            <div>
              <Img
                className={styles.howWorks_slider__image}
                fluid={data.giveTipCellphone1.childImageSharp.fluid}
                alt="Cellphone with PayTip"
              />
              <div
                className={styles.howWorks_slider__text}
              >
                {t('type_amount')}
              </div>
            </div>
            <div>
              <Img
                className={styles.howWorks_slider__image}
                fluid={data.giveTipCellphone2.childImageSharp.fluid}
                alt="Cellphone with PayTip"
              />
              <div
                className={styles.howWorks_slider__text}
              >
                {t('generated_qr_code')}
              </div>
            </div>
            <div>
              <Img
                className={styles.howWorks_slider__image}
                fluid={data.giveTipCellphone3.childImageSharp.fluid}
                alt="Cellphone with PayTip"
              />
              <div
                className={styles.howWorks_slider__text}
              >
                {t('waiter_on_device')}
              </div>
            </div>
          </Slider>
        </TabPanel>
        <TabPanel>
          <Slider
            className={`${styles.howWorks_slider} howWorks_arrows`}
            {...settings}
          >
            <div>
              <Img
                className={styles.howWorks_slider__image}
                fluid={data.receiveTipCellphone1.childImageSharp.fluid}
                alt="Cellphone with PayTip"
              />
              <div
                className={styles.howWorks_slider__text}
              >
                {t('signin_in_app')}
              </div>
            </div>
            <div>
              <Img
                className={styles.howWorks_slider__image}
                fluid={data.receiveTipCellphone2.childImageSharp.fluid}
                alt="Cellphone with PayTip"
              />
              <div
                className={styles.howWorks_slider__text}
              >
                {t('give_amount_and_tip_height')}
              </div>
            </div>
            <div>
              <Img
                className={styles.howWorks_slider__image}
                fluid={data.receiveTipCellphone3.childImageSharp.fluid}
                alt="Cellphone with PayTip"
              />
              <div
                className={styles.howWorks_slider__text}
              >
                {t('receive_tip_in_comfortable_way')}
              </div>
            </div>
          </Slider>
        </TabPanel>
      </Tabs>
      <div className={styles.howWorks_whyQR}>
        {t('why_qr_code')}
      </div>
      <div
        className={styles.howWorks_more}
        ref={giveMoreRef}
      >
        <div className={styles.howWorks_more__info}>
          <div>
            <p
              dangerouslySetInnerHTML={innerHTMLTranslation(t('why_qr_part1'))}
            />
            <p
              dangerouslySetInnerHTML={innerHTMLTranslation(t('why_qr_part2'))}
            />
            <p
              dangerouslySetInnerHTML={innerHTMLTranslation(t('why_qr_part3'))}
            />
          </div>
          <img
            className={`${styles.howWorks_more__image} ${styles.howWorks_more__imageBig} ${styles.howWorks_showDesktop}`}
            src={PayTipGradientQrCodeImg}
            alt="PayTip gradient QR Code"
          />
        </div>
        <img
          className={`${styles.howWorks_more__image} ${styles.howWorks_showMobile}`}
          src={PayTipGradientQrCodeImg}
          alt="PayTip gradient QR Code"
        />
        <a
          className={`general-button ${styles.howWorks_showMobile}`}
          href="https://app.paytip.pl/"
        >
          {t('check')}
        </a>
      </div>
      <button
        className="general-button"
        onClick={handleWhyQRClick}
        type="button"
        ref={toggleButtonRef}
      >
        {
          isGiveMoreOn
            ?
            (
              <>
                <img
                  className={styles.howWorks_more__fold}
                  src={Fold}
                  alt="PayTip gradient QR Code"
                />
                {t('fold')}
              </>
            )
            :
            t('know_more')
        }
      </button>
    </div>
  );
};

export default HowWorks;
